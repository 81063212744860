import { shrinkLogo, growLogo  } from './tools.js';

// console.log('USE_SPLIT_NAV :: ', use_split_nav);

if (typeof use_split_nav == 'undefined') {
        /* --------------------------------------------------------------------
        Hide header bar on scroll.
        Reveal after period of inactivity.
        -------------------------------------------------------------------- */
        let hideHeaderTriggerPoint = 100;
        var isWaiting, lastScrollPos;
        var lastFired = 0;

        window.addEventListener('scroll', function(){

            function getYPosition(){
                var top  = window.pageYOffset || document.documentElement.scrollTop
                return Math.ceil(top);
            }

            if(getYPosition() >= hideHeaderTriggerPoint){
                shrinkLogo();
            }
            else{
                growLogo();
            }

        });
};