// Import CSS
import styles from '../css/app.pcss';

console.log('Main.js\n');

/* ------------------------------------------------
CORE:
Load any critical scripts first, including functionality
that exists on every page.
------------------------------------------------*/
import globalEvents from './modules/globals/events.js';
import accessibility from './modules/globals/accessibility.js';
import animations from './modules/globals/animations.js';
import burgerMenu from './modules/globals/hamburger_menu.js';

import parallax from './modules/parallax.js';
import nearest_show_geolocator from './modules/geolocator.js';


// Lazy Load images
import LazyLoad from "vanilla-lazyload";
const lazyContent = new LazyLoad({});


/* ==========================================================================
========================================================================== */

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
